
  import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
  import { TaskStatus } from "@/store/modules/task.store";

  @Component({
    components: {
      GeneralStatusLabel: () => import("@/components/general/status-label.vue"),
    },
  })
  export default class FormTaskStatusSelect extends Vue {
    @Prop({ default: TaskStatus.todo }) value!: TaskStatus;

    show: boolean = false;

    hoveringStatus: TaskStatus | null = null;

    selected: TaskStatus = TaskStatus.todo;

    statuses = TaskStatus;

    styles: { [key in TaskStatus]: { borderColor: string; backgroundColor: string; color: string } } = {
      TODO: { borderColor: "#F1F2F7", backgroundColor: "#F1F2F7", color: "#333" },
      REQUESTED: { borderColor: "#FFCA54", backgroundColor: "#FFCA54", color: "#fff" },
      PLANNED: { borderColor: "#23BACF", backgroundColor: "#23BACF", color: "#fff" },
      DONE: { borderColor: "#1BCFA9", backgroundColor: "#1BCFA9", color: "#fff" },
      NA: { borderColor: "#8C8E90", backgroundColor: "#8C8E90", color: "#fff" },
      WAITING: { borderColor: "#FF6A5B", backgroundColor: "#FF6A5B", color: "#fff" },
    };

    get clickOutsideConfig() {
      return {
        handler: this.close,
        isActive: this.show,
        capture: true,
      };
    }

    close() {
      this.show = false;
    }

    toggleSelected(status: TaskStatus) {
      this.selected = status;
      this.close();
      this.handleInput();
    }

    @Emit("input")
    handleInput() {
      return this.selected;
    }

    @Watch("value", { immediate: true })
    handleValueChanged(newValue: TaskStatus) {
      this.selected = newValue;
    }
  }
